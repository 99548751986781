import { Box, Typography } from '@mui/material';
import { MetricByUserResponse, MetricByWeek } from '../../__generated__/graphql';
import { formatPostsCountDifference } from '../../utils';
import CardReport from '../Reports/CardReport';
import GraphicReportCompanyUserImpressionsForWeek from '../Reports/GraphicReportCompanyUserImpressionsForWeek';
import GraphicReportCompanyUserInteractionsForWeek from '../Reports/GraphicReportCompanyUserInteractionsForWeek';

interface Props {
  data: MetricByUserResponse;
}

const IndividualDashboard = ({ data }: Props) => {

  
  return (
    <>
      <Box
        display="flex"
        gap={2}
        alignContent="center"
        flexDirection={{ xs: 'column', md: 'row' }}
        flexWrap={{ xs: 'wrap', md: 'nowrap' }}
      >
        <Box width={{ sm: '50%', md: '20%' }}>
          <CardReport
            title="Connections"
            lastDays="in the last 30 days"
            subtitle={false}
            comparation={formatPostsCountDifference(
              data.followers,
              data.followers30DaysAgo,
            )}
          >
            <Typography fontSize="2rem" textAlign="center" mr="5px">
              {data.followers ? data.followers.toLocaleString('es-ES'): 'N/A'}
            </Typography>
            <Typography fontSize="1rem" textAlign="center">
              {' '}
              contacts + followers
            </Typography>
          </CardReport>
        </Box>
        <Box width={{ sm: '50%', md: '20%' }}>
          <CardReport
            title="Activity"
            lastDays="vs the previous 30 days"
            subtitle
            comparation={formatPostsCountDifference(
              data.countMetrics30Days,
              data.countMetrics60Days,
            )}
          >
            <Typography fontSize="2rem" textAlign="center" mr="5px">
              {data.countMetrics30Days}
            </Typography>
            <Typography fontSize="1rem" textAlign="center">
              {' '}
              posts
            </Typography>
          </CardReport>
        </Box>
        <Box width={{ sm: '50%', md: '20%' }}>
          <CardReport
            title="Impressions"
            lastDays="vs the previous 30 days"
            subtitle
            comparation={formatPostsCountDifference(
              data.impressions30DaysAgo,
              data.impressions60DaysAgo,
            )}
          >
            <Typography fontSize="2rem" textAlign="center" mr="5px">
              {!!data.metricsByWeek?.length ? data.impressions30DaysAgo.toLocaleString('es-ES') : 'N/A'}
            </Typography>
            <Typography fontSize="1rem" textAlign="center">
              {' '}
              impressions
            </Typography>
          </CardReport>
        </Box>

        <Box width={{ sm: '50%', md: '20%' }}>
          <CardReport
            title="Interactions"
            lastDays="vs the previous 30 days"
            subtitle
            comparation={formatPostsCountDifference(
              data.interactions30DaysAgo,
              data.interactions60DaysAgo,
            )}
          >
            <Typography fontSize="2rem" textAlign="center" mr="5px">
              {!!data.metricsByWeek?.length ? data.interactions30DaysAgo.toLocaleString('es-ES') : 'N/A'}
            </Typography>
            <Typography fontSize="1rem" textAlign="center">
              {' '}
              interactions
            </Typography>
          </CardReport>
        </Box>

        <Box width={{ sm: '50%', md: '20%' }}>
          <CardReport
            title="Engagement"
            lastDays="in the previous 30 days"
            subtitle
            comparation={formatPostsCountDifference(
              (data.interactions30DaysAgo / data.impressions30DaysAgo) * 100,
              (data.interactions60DaysAgo / data.impressions60DaysAgo) * 100,
            )}
          >
            <Box>
              <Typography marginTop="1rem" fontSize="2rem" textAlign="center" mr="5px">
                {data.interactions30DaysAgo && data.impressions30DaysAgo
                  ? `${(
                      (data.interactions30DaysAgo / data.impressions30DaysAgo) *
                      100
                    ).toFixed(2)}% `
                  : 'N/A'}
              </Typography>
              <Typography fontSize="1rem" textAlign="center">
                {' '}
                audience reaction
              </Typography>
            </Box>
          </CardReport>
        </Box>
      </Box>
      {data.metricsByWeek && (
        <>
          <GraphicReportCompanyUserImpressionsForWeek
            data={data.metricsByWeek as MetricByWeek[]}
          />
          <GraphicReportCompanyUserInteractionsForWeek
            data={data.metricsByWeek as MetricByWeek[]}
          />
        </>
      )}
    </>
  );
};

export default IndividualDashboard;

import { useMutation } from '@apollo/client';
import { ArrowBack } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { Brand, PostType } from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import { DeviceContext } from '../../context/DeviceContext';
import { PostContext } from '../../context/PostContext';
import { CHAT_MESSAGES_READ } from '../../graphql/mutations';
import { GET_POSTS_BY_SHELL } from '../../graphql/queries';
import Chat from '../ChatPost/Chat';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import PostEditor from './PostEditor';

interface Props {
  onClose: () => void;
  selectedBrand?: Brand | null;
  setSelectedBrandId?: (brandId: string | null) => void;
  onReadMessages?: () => void;
  isLink?: boolean;
}

const PostEditorModal = ({
  onClose,
  selectedBrand,
  setSelectedBrandId,
  onReadMessages,
  isLink = false,
}: Props) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
  const { isMobile } = useContext(DeviceContext);
  const [openChat, setOpenChat] = useState<boolean>(false);
  const { user } = useContext(AuthContext);
  const { postState } = useContext(PostContext);

  const { post, status, handlers } = postState ?? {};

  const handleClose = (forceClose: boolean = false) => {
    if (!!selectedBrand && !!setSelectedBrandId) {
      setSelectedBrandId(null);
    }

    if (!!!post?.content?.body && !!!post?.content?.media && !!!post?.title) {
      onClose();
      return;
    }

    if (
      !forceClose &&
      post.type !== PostType.AdvocacyParent &&
      status?.isNew &&
      !status?.isPosted
    ) {
      setConfirmationDialogOpen(true);
      return;
    }

    onClose();
  };

  const handleDiscard = () => {
    setConfirmationDialogOpen(false);
    onClose();
  };

  const handleConfirm = () => {
    setConfirmationDialogOpen(false);
    handlers?.handleSaveAsDraft({ callback: onClose });
  };

  const [chatMessagesRead] = useMutation(CHAT_MESSAGES_READ);
  const handleOpenChat = (open: boolean) => {
    setOpenChat(open);

    if (open && user && post?.chat) {
      if (onReadMessages) onReadMessages();
      handlers?.handleUnreadMessagesChange(0);

      chatMessagesRead({
        variables: {
          postId: post?._id,
          userId: user?._id,
        },
        refetchQueries: [GET_POSTS_BY_SHELL],
      });
    }
  };

  return (
    <Dialog
      open={true}
      fullScreen={isMobile}
      disableEscapeKeyDown
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ margin: 'auto' }}
      transitionDuration={0}
      onClose={() => {
        if (!isLink) {
          handleClose();
        }
      }}
      data-testid="post-editor-modal"
      scroll="body"
      maxWidth="md"
      keepMounted
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          maxWidth: 'none',
        },
      }}
    >
      {!status?.isPostLoaded && (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Box textAlign="center" width={'100px'} height={'100px'}>
            <CircularProgress />
          </Box>
        </Box>
      )}
      {status?.isPostLoaded && (
        <DialogContent
          sx={{
            p: 0,
            maxWidth: openChat ? '1350px' : '1100px',
            width: { lg: openChat ? '1350px' : '1100px' },
          }}
        >
          <ConfirmationDialog
            open={confirmationDialogOpen}
            onClose={handleDiscard}
            onConfirm={() => {
              setConfirmationDialogOpen(false);
            }}
            title={'Are you sure you want to close the editor?'}
            content={`If you leave now, your unsaved changes will be lost. What would you like to do?`}
            cancelText="Discard and leave"
            okText="Continue editing"
          />
          <Grid container columnSpacing={2}>
            <Grid item xs={12} md={!isMobile && openChat ? 8.5 : 12}>
              <Box sx={{ backgroundColor: 'white', padding: 2, borderRadius: '8px' }}>
                <PostEditor
                  onClose={handleClose}
                  openChat={openChat}
                  setOpenChat={handleOpenChat}
                  isLink={isLink}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={!isMobile && openChat ? 3.5 : 0}
              style={{ position: 'sticky', top: 0 }}
            >
              {!isMobile && openChat && (
                <Box
                  sx={{
                    backgroundColor: 'white',
                  }}
                  borderRadius="8px"
                  height={'500px'}
                >
                  <Chat
                    messages={post?.chat || []}
                    handleSendMessage={(message) =>
                      handlers?.handleSendChatMessage(message)
                    }
                    writing={false}
                  />
                </Box>
              )}
              {isMobile && openChat && (
                <Dialog open fullScreen>
                  <DialogContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      p: 0,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <IconButton onClick={() => setOpenChat(false)}>
                          <ArrowBack />
                        </IconButton>
                        <Typography fontWeight={'bold'}>Go back to post</Typography>
                      </Stack>
                    </Box>
                    <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                      <Chat
                        messages={post?.chat || []}
                        handleSendMessage={(message) =>
                          handlers?.handleSendChatMessage(message)
                        }
                        writing={false}
                      />
                    </Box>
                  </DialogContent>
                </Dialog>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default PostEditorModal;

import { useQuery } from '@apollo/client';
import { Close, KeyboardArrowRight, Undo } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { PostLifecycleState, PostsBrandsFilter } from '../../__generated__/graphql';
import {
  GET_BRANDS,
  GET_POST_LIFECYCLE_STATES,
  GET_SHELLS,
  GET_TAGS_BY_SHELL,
  GET_USERS,
} from '../../graphql/queries';
import { getFullName } from '../../utils';
import Avatar from '../Avatar/Avatar';
import {
  AdminFilters,
  FilterTab,
  PostsFilterContext,
} from '../../context/PostsFilterContext';
import { PostsFilterAdminContext } from '../../context/PostsFilterAdminContext';
import { AuthContext } from '../../context/AuthContext';

interface Props {
  setPage: (page: number) => void;
  onClose: () => void;
  open: boolean;
  advancedFilters?: boolean;
}

export const PostsFilter = ({
  setPage,
  open,
  onClose,
  advancedFilters = false,
}: Props) => {
  const [nameFilter, setNameFilter] = useState<string>('');
  const [labelFilter, setLabelFilter] = useState<string>('');
  const simpleFilterContext = useContext(PostsFilterContext);
  const advancedFilterContext = useContext(PostsFilterAdminContext);
  const { user } = useContext(AuthContext);

  const contextFilter = advancedFilters ? advancedFilterContext : simpleFilterContext;

  const {
    filter,
    setFilter,
    isFilterActive,
    initialFilterState,
    filterTab,
    setFilterTab,
    isFilterTabActive,
  } = contextFilter;

  const { data: dataBrands, loading: loadingBrands } = useQuery(GET_BRANDS, {
    variables: {
      allBrands: advancedFilters,
    },
    skip: filterTab !== FilterTab.Brands,
  });
  const { data: usersData } = useQuery(GET_USERS, {
    skip: filterTab !== FilterTab.Created,
  });

  const { data: shellsData } = useQuery(GET_SHELLS, {
    skip: filterTab !== FilterTab.Client,
  });

  const { data: tagsShell, loading } = useQuery(GET_TAGS_BY_SHELL, {
    variables: {
      shellId: user?.shellId,
    },
    skip: filterTab !== FilterTab.Type,
  });

  const FilterTabKeys = Object.keys(FilterTab)
    .filter((value) => !isNaN(Number(value)))
    .filter((value) => (advancedFilters ? true : !AdminFilters.includes(Number(value))))
    .map((value) => FilterTab[value as keyof typeof FilterTab]);

  const { data: lifecycleStatesResponse } = useQuery(GET_POST_LIFECYCLE_STATES, {
    variables: {
      allStates: advancedFilters,
    },
  });

  const lifecycleStates = [
    ...(lifecycleStatesResponse?.getPostLifecycleStates || []),
  ].sort(
    (a, b) =>
      (a?.lifecycleStateDisplay?.order || 0) - (b?.lifecycleStateDisplay?.order || 0),
  );

  const { control, handleSubmit, watch } = useForm<PostsBrandsFilter>({
    defaultValues: {
      lifecycleStates: filter.lifecycleStates || [],
      brandIds: filter.brandIds || [],
      dateFrom: filter.dateFrom || '',
      dateTo: filter.dateTo || '',
      tags: filter.tags || [],
      createdById: filter.createdById || null,
      shellId: filter.shellId || null,
      type: filter.type || null,
    },
    values: {
      lifecycleStates: filter.lifecycleStates || [],
      brandIds: filter.brandIds || [],
      dateFrom: filter.dateFrom || '',
      dateTo: filter.dateTo || '',
      tags: filter.tags || [],
      createdById: filter.createdById || null,
      shellId: filter.shellId || null,
      type: filter.type || null,
    },
  });

  const onResetFilters = () => {
    setPage(0);
    setFilter(initialFilterState);
  };

  // Form
  const watchClient = watch('shellId');
  const watchCreatedById = watch('createdById');
  const watchTags = watch('tags');

  const handleClose = () => {
    setNameFilter('');
    onClose();
    setLabelFilter('');
  };

  const onSuccess: SubmitHandler<PostsBrandsFilter> = (input) => {
    setPage(0);
    setFilter(input);
    handleClose();
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameFilter(e.target.value);
  };

  const onSearchLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLabelFilter(e.target.value);
  };

  const filteredBrands =
    dataBrands?.getBrands.filter((brand) =>
      brand!.name.toLowerCase().includes(nameFilter.toLowerCase()),
    ) || [];

  const filteredUsers =
    usersData?.users?.filter((user) =>
      getFullName(user).toLowerCase().includes(nameFilter.toLowerCase()),
    ) || [];

  const filteredShells =
    shellsData?.shells.filter((shell) =>
      shell?.name.toLowerCase().includes(nameFilter.toLowerCase()),
    ) || [];

  const filteredLabels =
    tagsShell?.getTagsByShell
      ?.filter((tag) => tag?.name?.toLowerCase().includes(labelFilter.toLowerCase()))
      .reverse() || [];

  return (
    <Dialog
      open={open}
      PaperProps={{ sx: { width: '700px', maxWidth: '700px' } }}
      onClose={handleClose}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '15px 15px 0 0',
          marginBottom: -1,
          cursor: 'pointer',
        }}
      >
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
      <Stack direction="row" sx={{ width: 'auto', p: 3, pt: 0 }} spacing={2}>
        <Stack direction="column" spacing={1}>
          <Typography
            fontWeight="bold"
            sx={{
              color: 'rgba(194, 11, 99, 1)',
              marginY: '0.5rem',
            }}
          >
            Filters
          </Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            <Stack direction="column" gap={1}>
              {FilterTabKeys.map((key, i) => {
                return (
                  <Button
                    key={key}
                    variant="outlined"
                    onClick={() => {
                      setNameFilter('');
                      setFilterTab(i);
                      setLabelFilter('');
                    }}
                    sx={{
                      width: '220px',
                      justifyContent: 'space-between',
                      color:
                        filterTab === i ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.56)',
                      border: '1px solid rgba(0, 0, 0, 0.23)',
                      backgroundColor: (() => {
                        if (filterTab === i) return 'rgba(0, 0, 0, 0.04)';
                        return isFilterTabActive(i) ? '#FFD8EB' : 'white';
                      })(),
                    }}
                  >
                    <Typography fontWeight={filterTab === i ? 700 : 'normal'}>
                      {String(key) === 'Date' ? 'Created Date' : key}
                    </Typography>
                    <KeyboardArrowRight
                      sx={{
                        color: 'rgba(0, 0, 0, 0.56)',
                      }}
                    />
                  </Button>
                );
              })}
              <Button
                variant="outlined"
                color="primary"
                onClick={onResetFilters}
                disabled={!isFilterActive}
                data-testid="reset-filters-button"
              >
                <Undo sx={{ marginRight: '10px' }} />
                <Typography fontWeight="bold">Reset filter</Typography>
              </Button>
            </Stack>

            <Divider
              orientation="vertical"
              sx={{ backgroundColor: 'rgba(0, 0, 0, 0.23)' }}
            />
          </Stack>
        </Stack>
        <form onSubmit={handleSubmit(onSuccess)} style={{ width: '100%' }}>
          <Stack direction="column" flex={1}>
            {filterTab === FilterTab.Status && lifecycleStates && (
              <List sx={{ maxHeight: 400, width: '100%', overflow: 'auto', mt: 4 }}>
                {lifecycleStates.map((state, i) => (
                  <ListItem key={i} sx={{ p: 0 }}>
                    <Controller
                      name="lifecycleStates"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Checkbox
                          sx={{ marginRight: '5px' }}
                          checked={value?.includes(
                            state?.lifecycleState as PostLifecycleState,
                          )}
                          onChange={() => {
                            const currentIndex = value?.indexOf(
                              state?.lifecycleState as PostLifecycleState,
                            );
                            const newValue = [...(value || [])]; // Crea una copia del valor actual

                            if (currentIndex === -1) {
                              newValue.push(state?.lifecycleState as PostLifecycleState); // Agrega si no existe
                            } else {
                              newValue.splice(currentIndex!, 1); // Elimina si existe
                            }

                            onChange(newValue); // Actualiza con el nuevo array
                          }}
                        />
                      )}
                    />
                    <Typography>{state?.lifecycleStateDisplay?.name}</Typography>
                  </ListItem>
                ))}
              </List>
            )}
            {filterTab === FilterTab.Type && (
              <List sx={{ maxHeight: 400, width: '100%', overflow: 'auto', mt: 4 }}>
                <TextField
                  placeholder="Search"
                  onChange={onSearchLabel}
                  InputProps={{
                    style: {
                      height: '40px',
                      borderRadius: '8px',
                      width: '320px',
                      backgroundColor: 'rgba(244, 244, 244, 1)',
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
                <Divider
                  sx={{
                    marginY: '10px',
                  }}
                />
                {loading && (
                  <Box
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}

                {(filteredLabels || []).map((tag) => (
                  <Controller
                    name="tags"
                    key={tag?._id}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ListItemButton
                        onClick={() => {
                          if (watchTags?.length) {
                            if (watchTags.includes(tag?._id!)) {
                              const newValue = watchTags.filter((t) => t !== tag!._id);
                              onChange(newValue);
                            } else {
                              onChange([...watchTags, tag!._id]);
                            }
                          } else {
                            onChange([tag!._id]);
                          }
                        }}
                        selected={
                          !!watchTags?.length
                            ? watchTags.includes(tag!._id!)
                            : filter.tags?.includes(tag!._id!)
                        }
                        sx={{
                          marginBottom: 1,
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: tag?.color,
                            borderRadius: '5px',
                            padding: '0 6px',
                            width: '20px',
                            height: '20px',
                            marginRight: '10px',
                          }}
                        />
                        <Typography>{tag?.name}</Typography>
                      </ListItemButton>
                    )}
                  />
                ))}
              </List>
            )}
            {filterTab === FilterTab.Date && (
              <Stack flexDirection="column" spacing={2} pt={3}>
                <Controller
                  name="dateFrom"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Start Date"
                      {...field}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
                <Controller
                  name="dateTo"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="End Date"
                      {...field}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Stack>
            )}
            {filterTab === FilterTab.Brands && (
              <>
                <TextField
                  placeholder="Search"
                  onChange={onSearch}
                  InputProps={{
                    style: {
                      height: '40px',
                      borderRadius: '8px',
                      width: '320px',
                      backgroundColor: 'rgba(244, 244, 244, 1)',
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
                <Divider
                  sx={{
                    marginY: '10px',
                  }}
                />
                <Box maxHeight={400} overflow="auto">
                  <Box display="flex" justifyContent="center">
                    {loadingBrands && <CircularProgress />}
                  </Box>
                  {filteredBrands.map((brand) => (
                    <Controller
                      key={brand._id}
                      name="brandIds"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <MenuItem
                          sx={{ display: 'flex', justifyContent: 'space-between' }}
                          onClick={() => {
                            const currentIndex = value?.indexOf(brand._id);
                            const newValue = [...(value || [])]; // Creates a copy of the current value

                            if (currentIndex === -1) {
                              newValue.push(brand._id); // Agrega si no existe
                            } else {
                              newValue.splice(currentIndex!, 1); // Elimina si existe
                            }

                            onChange(newValue); // Updates with the new array
                          }}
                        >
                          <Stack flexDirection="row" gap={2} alignItems="center">
                            <Avatar
                              avatar={brand.account?.avatar || ''}
                              name={brand.name}
                            />
                            <Typography>
                              {brand.name.length > 25
                                ? `${brand.name.slice(0, 25)}...`
                                : brand.name}
                            </Typography>
                          </Stack>
                          <Checkbox
                            sx={{ marginRight: '5px' }}
                            checked={value ? value.includes(brand._id) : false}
                          />
                        </MenuItem>
                      )}
                    />
                  ))}
                </Box>
              </>
            )}
            {filterTab === FilterTab.Client && (
              <>
                <TextField
                  placeholder="Search"
                  onChange={onSearch}
                  InputProps={{
                    style: {
                      height: '40px',
                      borderRadius: '8px',
                      width: '320px',
                      backgroundColor: 'rgba(244, 244, 244, 1)',
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
                <Divider
                  sx={{
                    marginY: '10px',
                  }}
                />
                <Box maxHeight={400} overflow="auto">
                  {filteredShells.map((shell) => (
                    <Controller
                      key={shell?._id}
                      name="shellId"
                      control={control}
                      render={({ field }) => (
                        <MenuItem
                          selected={
                            watchClient
                              ? watchClient === shell?._id
                              : filter.shellId === shell?._id
                          }
                          sx={{ display: 'flex', justifyContent: 'space-between' }}
                          onClick={() => field.onChange(shell?._id)}
                        >
                          <Stack flexDirection="row" gap={2} alignItems="center">
                            <Avatar
                              avatar={shell?.avatar || ''}
                              name={shell?.name || ''}
                            />
                            <Typography>{shell?.name}</Typography>
                          </Stack>
                        </MenuItem>
                      )}
                    />
                  ))}
                </Box>
              </>
            )}
            {filterTab === FilterTab.Created && (
              <>
                <TextField
                  placeholder="Search"
                  onChange={onSearch}
                  InputProps={{
                    style: {
                      height: '40px',
                      borderRadius: '8px',
                      width: '320px',
                      backgroundColor: 'rgba(244, 244, 244, 1)',
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
                <Divider
                  sx={{
                    marginY: '10px',
                  }}
                />
                <Box maxHeight={400} overflow="auto">
                  {filteredUsers.map((user) => (
                    <Controller
                      key={user._id}
                      name="createdById"
                      control={control}
                      render={({ field }) => (
                        <MenuItem
                          selected={
                            watchCreatedById
                              ? watchCreatedById === user._id
                              : filter.createdById === user._id
                          }
                          sx={{ display: 'flex', justifyContent: 'space-between' }}
                          onClick={() => field.onChange(user._id)}
                        >
                          <Stack flexDirection="row" gap={2} alignItems="center">
                            <Avatar avatar={user.avatar || ''} name={getFullName(user)} />
                            <Typography>{getFullName(user)}</Typography>
                          </Stack>
                        </MenuItem>
                      )}
                    />
                  ))}
                </Box>
              </>
            )}
          </Stack>
        </form>
      </Stack>

      <Stack
        flexDirection="row"
        justifyContent="flex-end"
        gap={1}
        padding="0 1rem 1rem 1rem"
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClose}
          data-testid="done-filters-button"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSuccess)}
          data-testid="done-filters-button"
        >
          Done
        </Button>
      </Stack>
    </Dialog>
  );
};
